
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'

import SellerMenuButtons from '@/components/Seller/Menu/SellerMenuButtons.vue'
import { State, Action } from 'vuex-class'

@Component({
  components: {
    SellerMenuButtons,
    SellerHeader,
  },
})
export default class SellerMenu extends Vue {
  @State(({ userState }) => userState.userInfo.fullname) fullname!: string
  @State(
    ({ userState }) => 'Hola ' + userState.userInfo.fullname.split(' ')[0] + '!'
  )
  headerText!: string
  @Action setUserName!: Function

  public logout(): void {
    this.$toast.success(`Hasta luego ${this.fullname}`)
    localStorage.clear()
    localStorage.removeItem('jwt')
    this.$router.push('/login')
  }

  mounted() {
    this.setUserName(localStorage.getItem('name'))
  }
}
