
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  Total,
} from '@/interfaces/seller/sales.interface'

import numeral from 'numeral';

Vue.filter('currency', function (value) {
  return numeral(value).format('$0,0.00');
});

@Component
export default class SalesComponent extends Vue {
  @Prop() private _totals: Total[];

  private totals: Total[] = [];

  async mounted() {

    this.totals = this._totals;
  }


  protected goToMySales(route: string): void {
    console.log('holas');
    const eventId = localStorage.getItem('selectedEvent')
    const jwt = localStorage.getItem('access_token')
    window.location.href =
      process.env.VUE_APP_REPORTS_APP_URL + route + eventId + '?jwt=' + jwt
  }

  //convert to currency


}
